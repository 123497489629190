import BSC from './56'

export const networks = {
  [BSC.id]: Object.assign(
    {
      label: 'BNB Smart Chain',
    },
    BSC,
  ),
}

export const DEVICE_TYPES = {
  UNKNOWN: 'unknown',
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
}

// https://www.techonthenet.com/js/language_tags.php
export const LOCALES = {
  EN_US: {
    label: 'English',
    value: 'en-US',
  },
  ZH_CN: {
    label: '简体中文',
    value: 'zh-CN',
  },
  ZH_TW: {
    label: '繁體中文',
    value: 'zh-TW',
  },
}
