<template>
  <svg :class="svgClass" aria-hidden="true" :width="computedWidth" :height="computedHeight">
    <use :xlink:href="iconName" :fill="color" />
  </svg>
</template>

<script setup>
const props = defineProps({
  iconClass: {
    type: String,
    required: true,
  },
  className: {
    type: String,
    default: '',
  },
  color: {
    type: String,
    default: '',
  },
  width: {
    type: [String, Number],
    default: '',
  },
  height: {
    type: [String, Number],
    default: '',
  },
})

const iconName = computed(() => `#icon-${props.iconClass}`)
const svgClass = computed(() => (props.className ? `svg-icon ${props.className}` : 'svg-icon'))

const computedWidth = computed(() => {
  if (props.width) return props.width
  if (props.height) return props.height
  return '1em'
})

const computedHeight = computed(() => {
  if (props.height) return props.height
  if (props.width) return props.width
  return '1em'
})
</script>

<style scoped lang="scss">
.svg-icon {
  position: relative;
  // width: 1em;
  // height: 1em;
  // fill: currentColor;
  vertical-align: -0.2em;
}
</style>
